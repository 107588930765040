import axios from '@/axios/index'



// 学习中心-首页
export function coureStudy(){
    return axios({
        url:'/api/app/v1/course/study/index',
        method:'post'
    })
}
// 学习中心-我的浏览
export function studyBrowse(data){
    return axios({
        url:'/api/app/v1/course/study/recent/browse',
        method:'post',
        params: data
    })
}
// 学习中心-已购课程列表
export function studyHasBuy(data){
    return axios({
        url:'/api/app/v1/course/study/has/buy',
        method:'post',
        params: data
    })
}

// 学习中心-我的课程 
export function coures(){
    return axios({
        url:'/api/app/v1/course/study/mine/coures',
        method:'post'
    })
}

// 学习中心- 课包列表  /api/app/v1/course/study/mine/live/list
export function courseList(coursePackageId,secondTypeId){
    return axios({
        url:'/api/app/v1/course/study/coursepackage/course/list?coursePackageId='+coursePackageId+"&secondTypeId="+secondTypeId,
        method:'post'
    })
}

// 学习中心- 我的直播 --  今日直播type=1   直播回放type=2
export function liveList(page,type){
    return axios({
        url:'/api/app/v1/course/study/mine/live/list?page='+page+"&type="+type,
        method:'post'
    })
}

// 学习中心- 我的直播 -- 直播课程:课程分类
export function courseTypeList(data){
    return axios({
        url:'/api/app/v1/course/package/live/course/type/list',
        method:'post',
        params: data
    })
}

// 学习中心- 我的直播 -- 直播课程:课程分类列表
export function liveCourseList(data){
    return axios({
        url:'/api/app/v1/course/package/live/course/list',
        method:'post',
        params: data
    })
}

// 学习中心- 我的直播/（全部直播/直播回放）
// export function mineLiveList(data){
//     return axios({
//         url:'/api/app/v1/course/study/mine/live/list',
//         method:'post',
//         params: data
//     })
// }

// 学习中心  我的直播  获取今日直播
export function liveTodayList(data) {
    return axios({
        url: '/api/app/v1/live/liveTodayList',
        method: 'post',
        data: data
    })
}

// 学习中心  我的直播  获取直播回放
export function livePlaybackList(data){
    return axios({
        url:'/api/app/v1/live/livePlaybackList',
        method:'post',
        data: data
    })
}

// 学习中心- 我的直播 --  直播列表
export function coursepackageList(page){
    return axios({
        url:'/api/app/v1/course/package/live/coursepackage/list?page='+page,
        method:'post'
    })
}

// 学习中心- 课程答疑
export function answerList(data){
    return axios({
        url:'/api/app/v1/course/study/coursepackage/mine/course/dayi/list',
        method:'post',
        params: data
    })
}

// 学习中心- 笔记
export function noteList(data){
    return axios({
        url:'/api/app/v1/course/study/coursepackage/mine/notes',
        method:'post',
        params: data
    })
}

// 学习中心- 修改笔记
export function editNote(data){
    return axios({
        url:'/api/app/v1/course/study/coursepackage/course/classhour/note/edit',
        method:'post',
        params: data
    })
}

// 学习中心- 删除笔记
export function deleteNote(data){
    return axios({
        url:'/api/app/v1/course/study/coursepackage/course/classhour/note/delete',
        method:'post',
        params: data
    })
}

// 学习中心- 删除笔记
export function liveList_tc(page,courseId){
    return axios({
        url:'/api/app/v1/course/package/live/list?page='+page+'&courseId='+courseId,
        method:'post'
    })
}