<template>
  <div class="mylive">
    <van-nav-bar
        class="navBar"
        fixed
        :placeholder="true"
        left-arrow
        @click-left="$router.go(-1)"
    >
      <template #title>
        <span>直播课程</span>
      </template>
    </van-nav-bar>
    <van-tabs v-model="activeName" @click='tabClick' color="#2470F4">
      <van-tab v-for="val in tabList" :title="val.name" :name="val.id">
        <div class="mylive_box" v-if="todayList.length > 0">
          <div class="list">
            <div class="item" v-for="(course, key) in todayList" :key="key">
              <div class="left">
                <img :src="course.coverPhotoPath" class="img"/>
              </div>
              <div class="right">
                <div class="title">{{ course.name }}</div>
                <div class="bottom">
                  <div class="btn" @click="onYuYue(course)">进入</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <van-empty v-else class="custom-image" :image="url" description="暂无课程"/>
      </van-tab>
    </van-tabs>


  </div>
</template>

<script>
import {courseTypeList, liveCourseList} from "@/api/study_course.js";

export default {
  data() {
    return {
      activeName: "",
      tabList: [],
      todayList: [],
      id: '',
      url: require('../../../assets/all_Icon/drawable-xxhdpi/no_data.png')
    }
  },
  name: 'liveCurriculum',
  created() {
    this.init();
  },
  methods: {
    init() {
      this.id = this.$route.query.id;
      this.getType();
    },
    getType() {
      courseTypeList({coursePackageId: this.id}).then(res => {
        this.tabList = res.data.data;
        if (this.tabList.length) {
          this.activeName = this.tabList[0].id
          this.TodayRefresh();
        }
      })
    },
    TodayRefresh() {
      let params = {
        coursePackageId: this.id,
        typeId: this.activeName
      }
      liveCourseList(params).then(res => {
        console.log(res.data);
        this.todayList = res.data.data;
      })
    },
    tabClick() {
      this.TodayRefresh();
    },
    onYuYue(val) {
      console.log(val);
      this.$router.push({ path: "/list", query: { id: val.id,coursePackageId:this.id } })
    }
  },
}
</script>

<style lang="less" scoped>
.mylive {
  width: 100%;
  height: 100%;

  /deep/ .van-empty__image {
    width: 100%;
    height: calc(100vh - 350px);
  }

  /deep/ .van-tabs__wrap {
    border-bottom: 1px solid #dddddd;
  }

  .mylive_box {
    height: calc(100vh - 90px);
    width: 100%;
    overflow-y: auto;

    .list {
      padding: 0px 12px;

      .item {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
        height: 72px;
        border-bottom: 1px solid #dddddd;

        .left {
          width: 109px;
          position: relative;
          margin-right: 9px;
          border-radius: 5px; /*no*/

          .img {
            width: 100%;
            border-radius: 5px; /*no*/
          }

          .mark {
            opacity: 0.6;
            position: absolute;
            left: 0;
            top: 0;
            background: #000000;
            border-radius: 5px 0px 5px 0px; /*no*/

            font-size: 11px; /*no*/
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            padding: 2px 4px;

          }
        }

        .right {
          width: calc(100% - 118px);
          position: relative;

          .title {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 550;
            color: #333333;
          }

          .bottom {
            position: absolute;
            bottom: 30px;
            right: 0;

            .btn {
              position: absolute;
              top: 0;
              right: 10px;
              background: #5d7dff;
              border-radius: 500px; /*no*/
              width: 67px;
              height: 28px;
              line-height: 28px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
</style>
